import { type ExpMediumData } from '~/models/ExpMedium'
export class ExpArticle implements IExpArticle {
	technicalData: Object[] = []
	slug: String = ''
	additionalArticles: String[] = []
	scopeOfDelivery: string = ''
	internationalArticleNumber: string = ''
	expRichContent: Boolean = false
}

export interface IExpArticle extends IArticle{
	technicalData?: Object[]
	slug?: String 
	nameSubscript?: string;
	additionalArticles?: String[] 
	scopeOfDelivery?: string 
	internationalArticleNumber?: string 
	expRichContent?: Boolean
	visibility?: 'INVISIBLE' | 'SEARCH' | 'SEARCH_AND_CATEGORY'
}

export interface IArticle {
	active?: boolean;
	descriptionList?: string[];
	description?: string;
	seoDescription?: string;
	seoPageTitle?: string;
	headline?: string;
	formerNettoPrice?: number;
	formerBruttoPrice?: number;
	formerTaxRate?: number;
	formerCurrency?: string;
	formerPriceState?: string;
	brandId?: string;
	webcode?: string;
	productType?: string;
	primaryImage?: string;
	imageGallery?: string[];
	documentList?: string[];
	articleId?: string;
	context?: string;
	featureLogos?: string[];
	title?: string;
	name?: string;
	primaryCategory?: string;
	crossSaleArticles?: string[];
	testResults?: ITestResult[];
}

export interface ITestResult {
	image?: string;
	link?: string;
	title?: string;
	description?: string;
	priority?: number;
}
