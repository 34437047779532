import { toValue } from 'vue'
import { ExpStore } from 'models/ExpLocalStore/exp_Localstore';
import { Price } from 'models/ExpPrice/exp_Price';
import { getLocalExpStore } from './expLocalStore';
/**
 * Get the Price
 * @date 11.7.2023 - 09:54:37
 *
 * @export
 * @async
 * @param {string} jwtString
 * @param {string} articleId
 * @param {string} store
 * @returns {}
 */
export function getPrice(articleId: string, store: Ref<ExpStore>, liveCheck: boolean, lazy?: boolean) {
	console.log('[bt-expPrice] fetch price for store ' + toValue(store)?.storeId," ;:  ",articleId,"  ; ;  ",store?.value?.storeId);
	const bref = ref(JSON.stringify({
		articleId: articleId,
		store: store?.value?.storeId,
		cacheLevel: (liveCheck) ? 'LIVE' : 'MOST_RECENT'
	}))
	return useFetch(useRuntimeConfig().public.apiUrl + '/api/neo/internal-pub-service/getArticleData', {
		method: 'POST',
		body: bref,
		key: 'getPrice-' + articleId + '-' + toValue(store).id + '-' + liveCheck,
		lazy: lazy !== undefined ? lazy : true,
		watch: [store],
		onRequest(context) {
			if (store.value === undefined) {
				let val = toValue(store) === undefined ? useStoreCookie() : toValue(store)?.storeId
				console.warn('[bt-expPrice] got request for price with undefined current store cookie has: ' + useStoreCookie().value + ' using ' + val);
			}
			console.debug('[bt-expPrice] request: for store ' + JSON.stringify({
				articleId: articleId,
				store: store?.value?.storeId,
				cacheLevel: (liveCheck) ? 'LIVE' : 'MOST_RECENT'
			}));
			bref.value = JSON.stringify({
				articleId: articleId,
				store: store?.value?.storeId,
				cacheLevel: (liveCheck) ? 'LIVE' : 'MOST_RECENT'
			})
			console.debug('[bt-expPrice] brefdated: for store ' + JSON.stringify(store?.value?.storeId));
		},
		onResponse(context) {
			let data = context.response._data;
			console.log('got price: ' ,context);
			const response: Price = {}
			try {
				if (data) {
					response.storeStock = data.stock
					response.storeReservedStock = data.orderedStock
					response.onlineStock = data.onlineStock
					response.onlineReservedStock = data.onlineOrderedStock
					response.storeId = data.onlineStore
					response.createdOn = data.createdOn;
					response.lastUpdated = data.lastUpdated;
					response.pubSource = 'neoPuB';
				}
				if (data?.price) {
					response.nettoPrice = data.price.net
					response.bruttoPrice = data.price.gross
					response.taxRate = data.price.taxRate
				}
				response.onlineButtonAction = data.onlineButtonAction
				if (data?.onlineAvailability) {
					response.onlineAvailability = data.onlineAvailability.color
					response.minOnlineDelivery = data.onlineAvailability.minLeadTimeDays
					response.maxOnlineDelivery = data.onlineAvailability.maxLeadTimeDays
				}
				if (data?.storeAvailability) {
					response.storeAvailability = data.storeAvailability.color
					response.minStoreDelivery = data.storeAvailability.minLeadTimeDays
					response.maxStoreDelivery = data.storeAvailability.maxLeadTimeDays
				}
				response.shipmentArray = new Array();
				if (data.onlineShipment !== null && data.onlineShipment !== undefined && Array.isArray(data.onlineShipment)) {
					for (let a = 0; a < data?.onlineShipment?.length; a++) {
						const ob = {}
						ob.shipmentType = data.onlineShipment[a].type
						ob.shipmentNettoPrice = data.onlineShipment[a].price?.net
						ob.shipmentBruttoPrice = data.onlineShipment[a].price?.gross
						ob.shipmentTaxRate = data.onlineShipment[a].price?.taxRate
						ob.shipmentCurrency = data.onlineShipment[a].price?.currency
						ob.hideVskText = data.onlineShipment[a].hideVskText
						response.shipmentArray?.push(ob)
					}
				}
				if (data.storeAvailability !== null) {
					response.storeButtonAction = data.storeButtonAction
					response.storeAvailability = data.storeAvailability?.color
				}
				response.itemOnDisplay = data.itemOnDisplay
				response.itemOnDisplayDescription = data.itemOnDisplayDescription
				response.availableServices = []
				if (data.availableServices !== null && Array.isArray(data.availableServices)) {
					for (let a = 0; a < data.availableServices.length; a++) {
						let ob = data.availableServices[a]
						ob.childService = []
						if (data.availableServices[a]?.parentServiceId == null) {
							for (let b = 0; b < data.availableServices.length; b++) {
								if (data.availableServices[a]?.expId == data.availableServices[b]?.parentServiceId) {
									ob.childService.push(data.availableServices[b])
								}
							}
							response.availableServices.push(ob)
						}

					}
				}
				response.showStoreName = data.showStoreName;

			} catch (error) {
				console.error('[bt-expPrice] trouble mapping the response ', error);
			}
			context.response._data = response;
			console.debug('[bt-expPrice] setting response data', JSON.stringify(response));
		},

	});
}
