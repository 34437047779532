import flagsmith from "flagsmith";
import version from '~/package.json';

export default defineNuxtPlugin((_nuxtApp) => {   
    try {
        if (process.client && useDevice()?.isCrawler == false)
            flagsmith.init({
                environmentID: useRuntimeConfig()?.public?.flagsmithClientEnv,
                api: useRuntimeConfig()?.public?.flagsmithUrl,
                cacheFlags: true,
                // enableAnalytics: true,
                identity: useExpCookieIdentifier()?.value,
                traits: {
                    version: version?.version,
                    build: useRuntimeConfig()?.public?.version?.build,
                    ismobile: useDevice()?.isMobile,
                    useragent: useDevice()?.userAgent,
                    storeid: useStoreCookie()?.value,
                    usergroup: useUserGroup()?.value,
                    lastseenonline: Date.now().toString()
                },
                cacheOptions: {
                    ttl: 30000,
                    skipAPI: true,
                },
                onChange: (oldFlags, params) => { // Occurs whenever flags are changed
                    // Determines if the update came from the server or local cached storage
                    const { isFromServer, flagsChanged } = params;
                    // console.debug('flagsmith onChange', oldFlags, params);
                    if (flagsChanged) {
                        const allFlags = flagsmith.getAllFlags();
                        // console.debug("allflags", allFlags);
                        for (let index = 0; index < Object.keys(allFlags).length; index++) {
                            const flag = Object.keys(allFlags)[index];
                            // console.debug('updating flag',flag)
                            useFeatureState(flag).value = flagsmith.hasFeature(flag);
                        }
                    }
                }
            });
        return {
            provide: {
                flags: flagsmith,
            },
        };

    } catch (error) {
        console.error('could not init flagsmith', error)
        return {};
    }
});

