
import type { RouterConfig } from '@nuxt/schema'

export default <RouterConfig>{
    scrollBehavior(to, from, savedPosition) {
        // `to` and `from` are both route locations
        // `savedPosition` can be null if there isn't one
        if (to.hash) {
            return new Promise((resolve, reject) => {
                let lost = false;
                let count = 0;
                let cleanUp;
                let interv = setInterval(() => {
                    let targ = document.getElementById(to.hash.slice(1));
                    if (targ && lost) {
                        count++;
                        window?.scrollTo({
                            top: (targ.offsetTop - 96),
                            behavior: 'smooth',
                        });
                        lost = false;
                        if (count > 2) {
                            cleanUp();
                            resolve(
                                {
                                    el: to.hash,
                                    top: 96,
                                    behavior: 'smooth',
                                }
                            )
                        }
                    } else {
                        lost = true
                    }
                }, 500)
                let scroll = (event) => {
                    cleanUp();
                }
                cleanUp = () => {
                    clearInterval(interv);
                    removeEventListener("wheel", scroll);
                }
                addEventListener("wheel", scroll);
            })

        }
    }
}