<template>
  <div>
    <NuxtLayout :name="useAppConfig()?.isAppUser ? 'app' : undefined">
      <div>
        <component :is="$device?.isLighthouse ? dynamicComp : 'div'">
          <BasicBtErrorBoundary>
            <ClientOnly>
              <LazyBasicSessionReplay v-if="useUserGroup()?.value == 'internal'" />
            </ClientOnly>
          </BasicBtErrorBoundary>
          <Transition mode="in-out">
            <div class="bubble flex gap-y-4 p-4 text-primary" v-if="showInfo">
              <div class="bubble-container container">
                <BasicIcon :name="'location-dot-solid'" class="inline-block w-4 text-primary" />
                <span class="pl-1 w-full">Sie wurden einem lokalem Fachmarkt in der Nähe zugewiesen!
                  <u>
                    <NuxtLink :external="true"
                      href="/Footer/Website/Datenschutzerklaerung#:~:text=16.%20Geo%2DLokalisierung%20f%C3%BCr%20den%20Fachmarkt%2DFinder">
                      Mehr erfahren</NuxtLink>
                  </u></span>
              </div>
            </div>
          </Transition>
          <NuxtPage />
          <NuxtLoadingIndicator :color="'#ff5e19'" :height="useDevice()?.isMobile ? 4 : 10" :duration="4" />
        </component>
      </div>
    </NuxtLayout>
  </div>
</template>

<script setup>
import { useBroadcastChannel } from "@vueuse/core";
import { provide } from "vue";

import { useCurrentStore, useStoreCookie } from "~/composables/states";
import { ProvideNames } from "~/composables/statics";
import { getExpUserProfile } from "~/composables/ExpApi/expUser";
import { useWindowSize, watchThrottled } from "@vueuse/core";
import { fetchNavigationTree, getExpNavigationTree } from "./composables/ExpApi/expCache";
import { useAppMessaging } from "./composables/appMessaging";
import { getWishlist } from "~/composables/expWishList";

const dynamicComp = useDevice()?.isLighthouse ? resolveComponent('DelayHydration') : 'div';

const { isSupported, data, post, error } = useBroadcastChannel({
  name: "fmarkt-channel",
});

useRouter().onError((err) => {
  console.error('"Routing / Navigation error"', err);
  // reloadNuxtApp();
});
if (useRequestHeaders()["bt-user-group"])
  useUserGroup().value = useRequestHeaders()["bt-user-group"];

useNuxtApp().vueApp.config.warnHandler = (msg, instance, trace) => {
  // `trace` is the component hierarchy trace
  if (!msg?.includes("Hydration")) {
    console.warn("[vue-warning] ", msg);
  }
};

watch(data, () => {
  if (useCurrentStore()?.value?.id != data?.value) {
    const rout = useRoute()?.fullPath?.toLowerCase();
    if (rout.includes("campaignid") || rout.includes("branch_id")) {
      useRouter().push({
        path: useRoute().path,
        query: {},
      });
    }
    nextTick(async () => {
      console.debug("got message for different store!! will switch");
      await changeStoreById(useStoreCookie()?.value);
    });
  }
});
let route = useRoute()?.fullPath?.toLowerCase();
if (route?.startsWith('/app'))
  useAppConfig().isAppUser = true;
else if (useAppSettingsCookie()?.value?.isApp)
  useAppConfig().isAppUser = true;

onNuxtReady(() => {

  // watch(useMessagePort(), () => {
  //   console.debug('message Port changed:', useMessagePort()?.value)
  //   if(useMessagePort()?.value != undefined) {
  //     useNuxtApp()?.$pwa?.getSWRegistration()?.active?.postMessage('messagePortChanged', [useMessagePort()?.value]);
  //   }
  // }, { immediate: true });
  // useNuxtApp()?.$pwa?.getSWRegistration()?.active?.postMessage('toast:onNuxtReady');
  try {
    route = useRoute()?.fullPath?.toLowerCase();
    // console.debug('got route',route);
    if (useRuntimeConfig()?.public?.isPWABuild || route.startsWith("/app")) {
      useNuxtApp()?.$flags?.setTrait("isAppUser", true);
      useAppSettingsCookie().value.isApp = true;
      useAppConfig().isAppUser = true;
    }
    if (route.includes('btdevice=android')) {
      useNuxtApp()?.$flags?.setTrait("appDeviceOs", "android");
      useAppSettingsCookie().value.isNativeApp = true;
      useAppSettingsCookie().value.isApp = true;
      useAppConfig().isAppUser = true;
      useNuxtApp()?.$flags?.setTrait("isAppUser", true);
      // if (useNotificationChannelState().value == false) {
      //   console.warn('adding message listener');
      //   useNuxtApp()?.$pwa?.getSWRegistration()?.active?.postMessage('toast:addMessageListener');
      //   window.addEventListener("message", function (event) {
      //     console.warn('got from component message', event)
      //     console.warn('data ', event.data);
      //     console.warn('ports ', event.ports);          

      //     var port = event.ports[0];
      //     if (typeof port === 'undefined') return;      
      //     port.onmessageerror = function (eveerr)  {
      //       console.error('[PostMessage] Got error', eveerr);
      //     };
      //     port.onmessage = function (eve) {
      //       let dat = eve.data;
      //       try {
      //         dat = JSON.parse(eve.data)
      //       } catch (error) {
      //         //
      //       }
      //       console.warn("[PostMessage] Got message ", dat);
      //       useAppMessaging(dat);

      //       useMessagePort().value = port;            
      //       port.postMessage("ACK " + dat);                        
      //     };
      //   })
      //   useNotificationChannelState().value = true;
      // }

    } else if (useDevice()?.isApplePWA) {
      useNuxtApp()?.$flags?.setTrait("appDeviceOs", "ios");
      useAppSettingsCookie().value.isNativeApp = true;
      useAppSettingsCookie().value.isApp = true;
      useNuxtApp()?.$flags?.setTrait("isAppUser", true);
    }
    if (route.includes('bttargetversion')) {
      useNuxtApp()?.$flags?.setTrait("appVersion", useRoute()?.query?.bttargetversion?.toString());
      useAppConfig().isAppUser = true;
    }
    if (route.includes('btversionenv')) {
      useNuxtApp()?.$flags?.setTrait("appVersionEnv", useRoute()?.query?.btversionenv?.toString());
      useAppConfig().isAppUser = true;
    }
    if (route.includes('btpushnotificationid')) {
      console.debug('got notificationId to track', useRoute()?.query?.btpushnotificationid?.toString())
      useAppConfig().isAppUser = true;
      useNuxtApp()?.$flags?.setTrait("btpushnotificationid", useRoute()?.query?.btpushnotificationid?.toString());
      useMarker('notification-opened');
      useGeneralTrick({
        eventType: "NOTIFICATION_OPEN",
        sessionId: useCurrentSessionIdentifier()?.value,
        json: {
          btpushnotificationid: useRoute()?.query?.btpushnotificationid?.toString(),
          url: [useRoute()?.fullPath],
        }
      })
    }
    if (route.includes('token')) {
      useAppConfig().isAppUser = true;
      try {
        useAppPushCookie().value = JSON.parse(useRoute()?.query?.btpushtoken?.toString() || useRoute()?.query?.token?.toString());
      } catch (error) {
        console.debug('error, using unparsed', error)
        useAppPushCookie().value = useRoute()?.query?.btpushtoken?.toString() || useRoute()?.query?.token?.toString();
      }
    }
  } catch (error) {
    console.error("could not set flagsmith trait", error);
  }

  if (useAppPushCookie()?.value != undefined) {
    useNuxtApp()?.$flags.setTrait("pushtoken", useAppPushCookie().value);
  }
});

onNuxtReady(async () => {
  watch(() => useRoute()?.fullPath, async () => {
    let { data: navigationTree } = await getExpNavigationTree("MAIN");
    if (!navigationTree.value) {
      navigationTree.value = await fetchNavigationTree("MAIN");
    }

    const routeParams = useRoute().params;

    let currCategory = navigationTree.value.find((node) =>
      node?.url === routeParams.parentCategory);

    if (useRoute().params.childCategory !== undefined) {
      currCategory = currCategory.descendants.find((node) =>
        node?.url === routeParams.childCategory);
      if (useRoute().params.productType !== undefined) {
        currCategory = currCategory.descendants.find((node) =>
          node?.url === routeParams.productType);
      }
    }

    const categoryParam = currCategory ? `&selectedCategoryId=${currCategory.extId}` : ``;

    const apiPath = `${useRuntimeConfig().public.apiUrl}/api/nea/canonical-service/canonicalProvider?url=${encodeURIComponent(location.href)}${categoryParam}`;
    const { data } = await useFetch(apiPath);

    if (data.value && data.value !== "") {
      useHead({
        link: [{
          rel: "canonical",
          href: data.value,
        }]
      });
    }
  }, { immediate: true });
});

watchEffect(async () => {
  if (useCurrentUserState()?.value?.shoppingCartId != undefined) {
    console.debug("updating carts", useCurrentUserState());
    const { data: sstate } = await fetchCurrentCart(
      useCurrentUserState().value.shoppingCartId
    );
    const { data: rstate } = await fetchCurrentCart(
      useCurrentUserState().value.reservationCartId
    );
    if (ProvideNames.ENABLE_CORRECT_CART_COUNTING) {
      //console.log('calculating cart count');
      if (sstate?.value) {
        sstate.value.overAllQuantity = 0
        for (let a = 0; a < sstate?.value?.itemList?.length; a++) {
          sstate.value.overAllQuantity += sstate?.value?.itemList[a].quantity;
        }
      }
      if (rstate?.value) {
        rstate.value.overAllQuantity = 0
        for (let a = 0; a < rstate?.value?.itemList?.length; a++) {
          rstate.value.overAllQuantity += rstate?.value?.itemList[a].quantity;
        }
      }
    }
    useShoppingCartItemsCount().value = !isNaN(sstate?.value?.overAllQuantity)
      ? sstate?.value?.overAllQuantity
      : 0;
    useReservationCartItemsCount().value = !isNaN(rstate?.value?.overAllQuantity)
      ? rstate?.value?.overAllQuantity
      : 0;
    useCurrentShoppingCart().value = sstate.value;
    useCurrentReservationCart().value = rstate.value;
  } else {
    // console.warn("no user, no cart");
  }
});

onNuxtReady(async () => {
  if (isSupported.value == true && useAppConfig()?.isAppUser != true) {
    post(useStoreCookie()?.value);
  }
  if (import.meta.client && useAppConfig()?.isAppUser != true) {
    if (useCurrentStore()?.value?.id != useStoreCookie()?.value) {
      console.debug("state and cookie do not match");
      await changeStoreById(useStoreCookie()?.value);
    }
    const abcookie = useCookie("expertShopABConfig", {
      maxAge: 72 * 3600,
      default: () => "false",
      sameSite: "strict",
    });
    const resW = watchEffect(async () => {
      if (useCurrentSessionIdentifier().value) {
        if (useFeatureState("bt-internal-test").value) {
          // startTracking()
        }

        // console.debug('ready to trick');
        if (abcookie?.value != undefined && abcookie.value != "true") {
          await useGeneralTrick({
            eventType: "VIEW_PAGE",
            sessionId: useCurrentSessionIdentifier()?.value,
            tickId: useExpCookieIdentifier()?.value,
            json: {
              content: "/consent/",
              btId: useExpCookieIdentifier()?.value,
              cookies: useCookie("UserCookiePermissions").value,
            },
          });
          abcookie.value = "true";
          resW();
        }
      }
    });
  }
});

if (!useDevice()?.isCrawler) {
  let heartbeatInterval;

  onNuxtReady(() => {
    heartbeatInterval = setInterval(function () {
      $fetch("/api/neo/frontend/_api/user/heartbeat", { method: "POST", body: "{}" });
    }, 600000);
  });

  onBeforeUnmount(() => {
    if (heartbeatInterval) clearInterval(heartbeatInterval);
  });
}
const currentStore = useCurrentStore();

const storeRef = {
  currentStore: currentStore,
  updateFunction: changeStoreById,
};

const ratingsCookie = getCookiePermissions(ProvideNames.PRODUCT_RATINGS);

callOnce('fetchPromotionsOnce', async () => {
  console.debug("fetching promotions");
  if (useDevice()?.isLighthouse == true || useState("isLighthouse")?.value == true) {
    console.debug("loading nothing");
  } else {
    useActivePromotions().value = await $fetch(useRuntimeConfig().public.apiUrl + "/api/activePromotions", { key: 'activePromotionData' })
    if (useFeatureState('ES-7171')?.value == true) {
      console.debug('feature ES-7171 active, checking if filtering required')
      if (useCurrentStore()?.value?.id != 'e_2879130') {
        let filterVal = JSON.parse(useFeatureValue('ES-7171')?.value?.toString())?.promoFilter
        if (!Array.isArray(filterVal))
          filterVal = [];
        console.debug('feature ES-7171 active and store is not central, filtering ', filterVal);
        if (useActivePromotions()?.value)
          useActivePromotions().value = useActivePromotions().value?.filter(promo => {
            // console.debug('ES-7171: Checking if ' + promo?.id + " is included in ", useFeatureValue('ES-7171')?.value)
            return !filterVal?.includes(promo.id)
          });
      }
    }
  }

})

watchEffect(async () => {
  if (useFeatureState('ES-7171')?.value == true) {
    console.debug('feature ES-7171 active, checking if filtering required')
    if (useCurrentStore()?.value?.id != 'e_2879130') {
      let filterVal = JSON.parse(useFeatureValue('ES-7171')?.value?.toString())?.promoFilter
      if (!Array.isArray(filterVal))
        filterVal = [];
      console.debug('feature ES-7171 active and store is not central, filtering ', filterVal);
      if (useActivePromotions()?.value && useActivePromotions()?.value?.filter)
        useActivePromotions().value = useActivePromotions().value.filter(promo => {
          // console.debug('ES-7171: Checking if ' + promo?.id + " is included in ", useFeatureValue('ES-7171')?.value)
          return !filterVal?.includes(promo.id)
        });
    }
  }
});


getExpNavigationTree('MAIN').data.value = await fetchNavigationTree('MAIN');
getExpNavigationTree('SUPER').data.value = await fetchNavigationTree('SUPER');
getExpNavigationTree('FOOTER').data.value = await fetchNavigationTree('FOOTER');
getExpNavigationTree('FOOTER_HARDLINKS').data.value = await fetchNavigationTree('FOOTER_HARDLINKS');
getExpNavigationTree('FOOTER_SOCIAL').data.value = await fetchNavigationTree('FOOTER_SOCIAL');

// if (process.server) {
onNuxtReady(() => {
  if (useDevice()?.isLighthouse == true || useState("isLighthouse")?.value == true || useIsCrawlerState()?.value || useAppConfig()?.isAppUser) {
    console.info("loading nothing");
  } else {
    const gtm = useGtm();
    if (gtm?.enabled() && gtm?.dataLayer()) {
      console.debug('[bt-default] gtm enabled and found!!! starting to push it real good', gtm?.dataLayer())
      if (gtm.dataLayer()?.find(dl => dl[1] == "ads_data_redaction")) {
        console.debug('[bt-default] gtm already filled, skipping', gtm?.dataLayer())
      }
    } else {
      console.info('gtm is disabled')
    }

    useHead({
      script: [{
        src: "https://www.googletagmanager.com/gtm.js?id=GTM-KM6K3JR",
        type: "text/javascript",
        async: "true",
        "data-cookieconsent": "ignore",
        id: 'googletagmanager'
      }],
    });

    gtm?.push({ 'gtm.start': Date.now(), event: 'gtm.js' });

    gtm?.push({
      'consent': 'default', event: {
        ad_storage: "denied",
        analytics_storage: "denied",
        functionality_storage: "denied",
        personalization_storage: "denied",
        security_storage: "granted",
        wait_for_update: 500,
      }
    })

    gtm?.push({ 'set': 'ads_data_redaction', event: true })

    useHead({
      script: [{
        src: "https://consent.cookiebot.eu/uc.js",
        "data-cbid": "36f7f725-f52e-4678-a71c-fe212fc2c34e",
        id: "CookieDeclaration",
        type: "text/javascript",
        'data-blockingmode': computed(() => (!useRoute()?.fullPath?.toLowerCase().includes('/checkout') || useRoute()?.fullPath?.toLowerCase().includes('/confirmation')) ? 'auto' : 'none')
      }]
    });

    window.addEventListener("CookiebotOnConsentReady", function () {
      console.debug('handling Cookiebot Callback');
      var prefs = { REQUIRED: Cookiebot.consent.necessary, STATISTICS: Cookiebot.consent.statistics, MARKETING: Cookiebot.consent.marketing, PRODUCT_RATINGS: Cookiebot.consent.preferences };
      var currentCookie = document.cookie.split(";").map(function (cookie) { return cookie.trim(); }).filter(function (cookie) { return cookie != null && cookie.indexOf("UserCookiePermissions") == 0; })[0];
      if (currentCookie == null) {
        currentCookie = {};
      } else {
        try {
          currentCookie = JSON.parse(decodeURIComponent(currentCookie.split("=").pop()));
        } catch (err) {
          currentCookie = {};
        }
      }
      if (true || Object.keys(prefs).filter(function (cookieName) { return prefs[cookieName] != currentCookie[cookieName] }).length > 0) {
        $fetch("/api/neo/frontend/_api/user/consent", {
          body: JSON.stringify({
            preference: prefs,
            privacyPreferenceInfo: "CookiebotRenderPostProcessor",
            IABConsentString: Cookiebot.IABConsentString,
            mobile: window?.isMobileDevice
          }), method: "POST", headers: {
            'bt-use-user-auth': 'true',
            'csrf-token': useCurrentUserState()?.value?.csrfToken
          }
        })
        document.cookie = "UserCookiePermissions=" + encodeURIComponent(JSON.stringify(prefs));
      }
    });

  }
});

const showInfo = ref(false);

const { width, height } = useWindowSize();

function setDevice(width) {
  if (width < 1200 && useDevice().isDesktop) {
    // console.log('setting mobile for desktop')
    useDevice().isMobile = true;
    useDevice().isDesktop = false;
  } else if (width > 1200 && useDevice().isMobile) {
    // console.debug('setting desktop for desktop')
    useDevice().isMobile = false;
    useDevice().isDesktop = true;
  }
}

setDevice(width.value)
watch(width, (_someVal) => {
  setDevice(width.value)
});

onNuxtReady(async () => {
  if (useDevice()?.isLighthouse || useDevice()?.isCrawler) {
    let cb = document?.getElementById("CookieDeclaration");
    if (cb) {
      cb.remove();
    }
  } else if (!ratingsCookie?.value) {
    let bv = document?.getElementById("bazaarvoicejs");
    if (bv) {
      bv.remove();
    }
  }

  if (
    useRoute()?.fullPath?.toLowerCase().includes("campaignid") &&
    (useStoreCookie()?.value != "e_2879130" ||
      useCurrentStore()?.value?.id != "e_2879130")
  ) {
    console.debug("found campaign param but store is wrong");
    await nextTick(async () => {
      await changeStoreById("e_2879130");
    });
  }

  if (!useCurrentUserState()?.value && !useDevice()?.isCrawler) {
    //console.log('[bt-default] fetching cart states')
    const expUser = await getExpUserProfile();
    useCurrentUserState().value = expUser;
  }

  if (useCookie("fmarkt_autoComplete").value !== undefined && !useDevice()?.isCrawler && !useAppConfig()?.isAppUser && !useAppSettingsCookie()?.value?.isApp) {
    //console.log('fm auto cookie was set, checking if cookie switch required')
    // $toast.show('this is a test');
    const oldStore = useCurrentStore().value?.id;
    if (
      useCookie("fmarkt_autoComplete").value !== oldStore ||
      useCookie("fmarkt_autocomplete").value !== useCurrentStore().value?.id
    ) {
      //console.log('switching cookie with api call');
      let newStore = await useFetch(
        useRuntimeConfig().public.apiUrl + "/api/switchStore",
        {
          server: false,
          params: { branch_id: unref(useRoute()?.query)?.branch_id },
          key: Date.now() + "store",
        }
      );
      if (
        oldStore !== newStore.data.value?.newStore ||
        useCurrentStore().value?.id !== newStore.data.value?.newStore
      ) {
        await changeStoreById(newStore.data.value?.newStore);
        nextTick(() => {
          showInfo.value = true;
          new Promise((resolve) => setTimeout(resolve, 10000)).then((res) => {
            showInfo.value = false;
          });
        });
      }
    }
  }
  //console.log("fmarktcookie_opened : ", useCookie('fmarktcookie_opened').value)
  if (
    (useCookie("fmarktcookie_opened").value === "false" ||
      useCookie("fmarktcookie_opened").value === undefined) &&
    useRoute().query?.campaignId === undefined &&
    useRoute().query?.branch_id === undefined
  ) {
    if (useDevice()?.isApplePWA || useNuxtApp()?.$pwa?.isPWAInstalled || useDevice()?.isLighthouse || useDevice()?.isCrawler) {
      return;
    }
    console.warn("auto assigning store");
    const storeFromCookie = await useFetch(
      "/api/storeFinder",
      { params: { maxResults: 1 }, method: "GET" }
    );
    useCookie("fmarktcookie_opened", { domain: useRuntimeConfig()?.public?.cookieDomain }).value = "true";
    if (useStoreCookie().value === undefined)
      useStoreCookie().value = storeFromCookie?.data?.value?.at(0)?.store?.id;
    // useCookie('fmarkt_autoComplete').value = storeFromCookie?.data?.value[0]?.store?.id;
    //console.log("update function called", storeFromCookie?.data?.value?.at(0)?.store?.id);
    await changeStoreById(storeFromCookie?.data?.value?.at(0)?.store?.id);
    nextTick(() => {
      showInfo.value = true;
      new Promise((resolve) => setTimeout(resolve, 10000)).then((res) => {
        showInfo.value = false;
      });
    });
  } else if (
    useCurrentStore().value?.id === undefined &&
    useStoreCookie().value !== undefined
  ) {
    await changeStoreById(useStoreCookie().value);
  }

  watch(() => useExpCookieIdentifier()?.value, async () => {
    if (useExpCookieIdentifier()?.value) {
      console.debug('fetching wishList');
      useCurrentWishList().value = (await getWishlist());
    }
  }, { immediate: true })

});

// fetchCurrentWishList();

provide(ProvideNames.CURRENT_STORE_KEY, storeRef);

provide(ProvideNames.BASE_DIR, "/shop/unsere-produkte/");
provide(
  "ImagePlaceHolder",
  "/17/89/06/2d7e8a948fb264651203723a2101d1283b/defaultImage_100x100_90-0.jpeg"
);
</script>

<style>
.primaryColor {
  color: #ff5e19;
}

.page-enter-active,
.page-leave-active {
  transition: all 0.4s;
}

.page-enter-from,
.page-leave-to {
  opacity: 0;
  filter: blur(1rem);
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.bubble {
  text-align: center;
  background-color: aliceblue;
}

.bubble-container {
  max-width: 1200px;
  max-height: 120px;
  margin: auto;
}
</style>
