<template>
  <template v-if="useCurrentStore().value?.videoConsultation && advisor?.id">
    <input type="checkbox" id="toggleAdvisorPopup" @change="focused($event)" class="hidden"/>
    <div class="articleAdvisor">
      <Modal
        v-if="showAdvisorModal"
        @close-modal="toggleModal"
      >
        <object :data="advisorLink" class="advisorExternal"></object>
      </Modal>
      <div class="infoWrapper">
        <div class="advisorHeader">
          <div class="translateTrack">
            <template v-if="advisorAvailable">
              <span class="font-bold block">Jetzt mit einem Berater sprechen</span>
              <span
                class="advisorLink"
                @click="openAdvisor"
              >
              Zur Live-Beratung
            </span>
            </template>
            <template v-else>
              <span class="font-bold block">Derzeit ist keine Beratung möglich</span>
            </template>
          </div>
        </div>
        <div class="roundCorners"></div>
        <label class="advisorIcon" for="toggleAdvisorPopup">
          <BasicIcon :name="'person-with-headset'" class="w-full h-full text-white" />
        </label>
        <ArticleAdvisorTimeSlots
          class="advisorFooter translateTrack"
          :displayDurations="advisor?.displayDurations" />
      </div>
    </div>
  </template>
</template>

<script setup lang="ts">
import type { Ref } from "@vue/reactivity";
import { ExpArticle } from "~/models/ExpArticle/exp_Article";
import type { ExpArticleAdvisor } from "~/models/ExpArticle/exp_ArticleAdvisor";
import articleAdvisorService from "~/composables/ExpApi/expArticleAdvisor";
import { getExpBrand } from "~/composables/ExpApi/expCache";
import { getExpArticleCategory } from "~/composables/ExpApi/expArticle";

const props = defineProps({
  article: {
    type: ExpArticle,
    required: true
  }
});
const advisorLink = ref('');
const advisorAvailable = ref(false);
const advisor: Ref<ExpArticleAdvisor> = ref({});
const showAdvisorModal = ref(false);

const toggleModal = () => {
  showAdvisorModal.value = !showAdvisorModal.value;
}

const openAdvisor = async () => {
  setTrackingMarker('Videoberatung', 'Start');
  if (advisor.value?.overlay) {
    toggleModal();
  } else {
    await navigateTo(advisorLink.value, {
      open: {
        target: '_blank',
      }
    })
  }
};

const focused = async (e: Event) => {
  const input = e.currentTarget as HTMLElement;
  if (input.classList.contains("isFocused")) {
    input.classList.remove("isFocused")  
  } else {
    input.classList.add("isFocused")
  }
};

const getSubPageForAdvisor = (): string => {
  const { data: brand } = getExpBrand(props?.article?.brandId);
  return brand?.value ? `${brand?.value?.name.toLowerCase()}_expert` : '';
}

const getTopicForAdvisor = async (): Promise<string> => {
  const { data: brand } = getExpBrand(props?.article?.brandId);
  const { data: primaryCategory } = await getExpArticleCategory(props?.article?.primaryCategory);

  return brand?.value && primaryCategory?.value ? `${brand?.value?.name}_${primaryCategory?.value?.contextSensitiveData?.name}` : '';
}

const getArticleAdvisor = async ():Promise<ExpArticleAdvisor> => {
  const { data: advisor } = await useFetch(useRuntimeConfig().public.apiUrl + '/api/nea/consultation-service/brand/' + props?.article?.brandId);
  return advisor?.value?.categories?.includes(props?.article?.primaryCategory) ? advisor.value : null;
}

const generateQueryString = async () => {
  const accessToken = advisor?.value?.token;
  const topicId = await getTopicForAdvisor();
  const analyticsParameter = useExpCookieIdentifier()?.value;
  const storeNumber = useCurrentStoreId();

  return `access_token=${accessToken}&t=${topicId}&ics=${analyticsParameter}&loc=${storeNumber}`
}

const encodeQueryString = (query: string) => {
  return query?.replace(' ', '-');
}

const createAdvisorLink = async () => {
  const baseUrl = 'https://communicator.pos-live.de';
  const advisorLang = 'de';
  const subPage = getSubPageForAdvisor();
  const query = await generateQueryString();
  const encodedQuery = encodeQueryString(query);

  return `${baseUrl}/${advisorLang}/${subPage}?${encodedQuery}`;
};

const setTrackingMarker = (markerName: string, action: string) => {
  useMarker(`${markerName}_${action}`);
  sendEmosMarker(markerName, action);
}

const sendEmosMarker = (markerName: string, action: string) => {
  let contentUrl = useRoute().path;
  contentUrl = useTrimStart(contentUrl, '/');
  contentUrl = useTrimEnd(contentUrl, '.html');

  useNuxtApp()?.$emos3.send({
    content: contentUrl,
    'Target': [markerName, action, 'a'],
  });
}

if (props?.article) {
  advisor.value = await getArticleAdvisor();
  advisorAvailable.value = articleAdvisorService.available(advisor?.value);
  advisorLink.value = await createAdvisorLink();
}
</script>