<template>
  <template v-if="props?.userAccount !== '' && props?.userAccount !== null">
    <OnClickOutside
      class="useraccount flyout flex flex-col border border-solid border-secondary w-full left-0 sm:left-auto sm:right-0 sm:top-full fixed sm:absolute sm:w-[300px] text-paragraph"
      @trigger="close"
    >
      <div v-if="profilePayload && loggedIn">
        <div class="p-4 border solid border-secondary ">
          <div class="text-lg" >
            Hallo {{ username }}
          </div>
        </div>
        <div class="p-4 bg-secondary flex flex-col gap-4 border solid border-secondary">
          <NuxtLink to="/mein-expert/persoenliche-daten" :external="true">
            <div class="flex gap-4">
              <img
                class="w-4 scale-150 text-black"
                src="~/assets/mediums/Icons/address-card-regular.svg"
              />
              Meine persönlichen Daten
            </div>
          </NuxtLink>
          <NuxtLink to="/mein-expert/persoenliche-daten#mydata" :external="true">
            <div class="flex gap-4" >
            <img
              class="w-4 scale-150 text-black"
              src="~/assets/mediums/Icons/house-chimney-solid.svg"
            />
            Meine Adresse
          </div>
          </NuxtLink>
          <NuxtLink to="/mein-expert/bestellungen" :external="true">
            <div class="flex gap-4 items-center" >
              <BasicIcon class="w-4 align-text-top text-primary" :name="'cart-shopping-solid'" /> 
              Meine Bestellungen
            </div>
          </NuxtLink>
          <NuxtLink :to="'/shop/wishlist'">
            <div class="flex gap-4 items-center" >
              <BasicIcon class="w-4 h-4 text-primary cursor-pointer align-text-top" :name="'heart-shape'" />
              Mein Merkzettel
            </div>
          </NuxtLink>
        </div>
        <button class="btn-primary w-full p-4 text-center border solid border-primary" @click="logout()">
          Abmelden
        </button>
      </div>
      
      <div v-else>
        <div class="p-4 gap-4 items-center flex">
          <img class="w-4 scale-150" src="~/assets/mediums/Icons/lock-solid.svg" />
          <div>
            <div class="text-lg">Sie sind nicht eingeloggt</div>
            <div>
              <NuxtLink class="text-primary text-sm" to="/Anmelden" :external="true">Jetzt einloggen</NuxtLink>
            </div>
          </div>
        </div>
        <NuxtLink class="btn-primary p-4 gap-2 items-center flex w-full text-sm" to="/registrieren" :external="true">
          <img
            class="w-4 scale-150"
            src="https://cdn.expert.de/static/resources/private/expert/icons/img-expert-symbol-white.svg"
          />
          Neu bei expert? <strong>Jetzt Registrieren</strong>
        </NuxtLink>
      </div>
    </OnClickOutside>
  </template>
</template>
<script setup lang="ts">
import { OnClickOutside } from "@vueuse/components";
import { ref } from "vue";
import { getExpUserProfile } from "~/composables/ExpApi/expUser";

let username = ''
const profilePayload = await getExpUserProfile();
if(profilePayload && profilePayload.defaultAddress 
  && profilePayload.defaultAddress !== '' 
  && Array.isArray(profilePayload.addresses)
  && profilePayload.addresses.length > 0) {
  const a = profilePayload.addresses.find(address => address.id == profilePayload.defaultAddress)
  username = a.firstName + ' ' + a.lastName
}
let loggedIn = false
if (profilePayload && Array.isArray(profilePayload.roles) && profilePayload.roles.length > 1) {
  loggedIn = true
}
if(profilePayload && Array.isArray(profilePayload.roles) && profilePayload.roles.length == 1) {
  loggedIn = (profilePayload.roles[0] == 'ANONYMOUS' ? false : true)
}
const props = defineProps({
  userAccount: {
    type: Object,
  },
});
const logout = async () => {
  await useFetch('/api/neo/frontend/_api/user/logout',{
		headers: {
			'bt-use-user-auth': 'true',
			'csrf-token': profilePayload.csrfToken
		},
		method: 'POST'
	})
  return await navigateTo('/', {external: true});
}

const showUserAccountFlyout = ref(false);
function close() {
  emit("changeFlyOutState", showUserAccountFlyout);
}
const emit = defineEmits("changeFlyOutState");
</script>
