import { isProxy, toRaw } from "vue";

/**
 *
 
 * and the current user and shopping cart ids
 */
export const getExpUserProfile = async (sessionParam?: string) => {
	if (process.server && !sessionParam) {
		console.log("[bt-expUser] returning empty user for ssr");
		return;
	}
	let sessionId = undefined;
	try {
		if (sessionParam) sessionId = sessionParam;
		else sessionId = useCookie("session", useRuntimeConfig().sessionCookieConfig).value;
	} catch (error) {
		console.log("[bt-expUser] could not fetch session " + error);
	}
	try {
		const { data: userProfile } = await useFetch('/api/expUserProfile', { retry: 3, retryStatusCodes: [404,408, 409, 425, 429, 500, 502, 503, 504]});
		if (userProfile && !sessionId) {
		}
		let toBeReturned = userProfile.value;
		if (userProfile.value?.shoppingCartId !== undefined) {
			if (isProxy(toBeReturned)) {
				toBeReturned = toRaw(toBeReturned);
			}
		}
		if (toBeReturned?.storeId !== useStoreCookie()?.value) {
			console.warn("[bt-expUser] user profile storeID and store ID do not match!");
		}
		useCurrentUserState().value = toBeReturned;
		const a = toBeReturned?.addresses?.find(
			(address: any) => address.id == toBeReturned?.defaultAddress
		);
		useCurrentSessionIdentifier().value = toBeReturned?.sessionIdentifier;
		if (toBeReturned?.roles?.length > 1) {
			// useNuxtApp().$flags.setTrait('email_domain', a?.email?.split('@')?.at(1))
			// useExpCookieIdentifier().value = 'systemuser_' + toBeReturned?.userId;
			// refreshCookie('expertShopABIdentifier');
			useNuxtApp().$flags.identify('bt_' + toBeReturned?.userId);
			useNuxtApp().$flags.setTrait('email_domain', a?.email?.split('@')?.at(1))
			refreshCookie('expertShopABIdentifier');
		}

		return toBeReturned;
	} catch (error) {
		console.log("[bt-expUser] error trying to fetch session info" + error);
		useCookie("session", useRuntimeConfig().sessionCookieConfig).value = undefined;
	}
}

export function fetchExpUserProfile() {
	return useFetch("/api/expUserProfile", { retry: 1, retryStatusCodes: [404,408,409,425,500,502,503,504]});
}

/**
 * Handles refreshing the CSRF Token from the CMS Services
 * @date 11.7.2023 - 09:55:44
 *
 * @export
 * @async
 * @param {?string} [sessionId]
 * @returns {ExpUserProfile}
 */
export async function refreshCSRFToken(sessionId?: string) {
	if (process.server && sessionId === undefined) {
		console.log("[bt-expUser] returning empty user for ssr");
		return;
		// return new ExpUserProfile();
	}
	let session = sessionId;
	if (sessionId === undefined) {
		session = "" + useCookie("session", useRuntimeConfig().sessionCookieConfig).value;
		if (sessionId !== session) {
			console.warn("[bt-expUser] found different session to check than in cookie");
			if (sessionId) {
				session = sessionId;
			}
		}
	}
	const userProfile = useCurrentUserState().value;

	if (userProfile != undefined && userProfile != null) {
		if (userProfile?.storeId != useCurrentStore()?.value?.id) {
			if (userProfile?.csrfToken == undefined) {
				const { error, data } = await useFetch("/_api/user/getCsrfToken", {
					query: {
						branch_id: useCurrentStore()?.value?.id,
					},
					headers: {
						"bt-vue-client": "nuxt",
						"bt-use-user-auth": "true",
						cookie: session,
					},
				});
				userProfile.csrfToken = data?.csrfToken;
			}
			console.warn(`[bt-expUser] stores do not match!! profileState=${userProfile?.storeId} and currentStore=${useCurrentStore()?.value?.id}`);
			const { error, data } = await useFetch("/_api/storeFinder/assignStore", {
				method: "POST",
				headers: {
					"bt-vue-client": "nuxt",
					"bt-use-user-auth": "true",
					"csrf-token": userProfile?.csrfToken,
					cookie: session,
				},
				body: JSON.stringify({
					storeId: useCurrentStore()?.value?.id,
				}),
			});
			console.info(`[bt-expUser] got assignStore result: ${data.value?.id}`);
			useStoreCookie().value = userProfile?.storeId;

			if (error?.value) {
				console.error("[bt-expUser] Could not fetch fresh csrf token " + error.value);
			} else if (data?.value) {
				userProfile.csrfToken = data?.value?.csrfToken;
				// console.debug('[bt-expUser] refreshed token to ' +userProfile.csrfToken);
				// nuxtStorage?.localStorage?.setData("expUserProfile", userProfile, 20, "m");
			}
		}
	}
	return userProfile;
}