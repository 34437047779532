// import process from "node:process";
import { useBroadcastChannel, useVibrate } from '@vueuse/core'
import { fetchNavigationTree, getExpNavigationTree } from './ExpApi/expCache';

export const clickAnchorForAccordionPanel = ($event: MouseEvent, navId?: string) => {
	const target = $event.target as HTMLAnchorElement;
	let id: string = navId ? navId : "";
	if (id == "") {
		if (target.href && target.href.split("#").length > 1) {
			id = target.href.split("#")[1];
		}
	}
	if (id !== "") {
		const element = document.getElementById(id);
		if (element) {
			element.dispatchEvent(
				new CustomEvent("customOpen", {
					bubbles: false,
				})
			);
		}
	}
	return true;
};

export const useReactiveSearchCreds = () => {
	const flagResult = useFeatureValue('CFG-PublicInClient-Search-Elastic').value
	// console.debug('got elastic flagResult', flagResult);
	if (flagResult) {
		const flags = JSON.parse(flagResult.toString());
		let res = {
			reactiveBaseAppName: flags['indexName'],
			reactiveBaseAppUser: flags['reactiveBaseAppUser'],
			reactiveBaseAppPassword: flags['reactiveBaseAppPassword'],
			reactiveBaseSuggestName: flags['suggestIndexName']
		};
		// console.debug('returning', res);
		return res;
	}
	// console.debug('returning default');
	return {
		reactiveBaseAppName: useRuntimeConfig()?.public?.reactiveBaseAppName,
		reactiveBaseAppUser: useRuntimeConfig()?.public?.reactiveBaseAppUser,
		reactiveBaseAppPassword: useRuntimeConfig()?.public?.reactiveBaseAppPassword,
		reactiveBaseSuggestName: useRuntimeConfig()?.public?.reactiveBaseSuggestName,
	};
};

export const useEasterEgg = (eggName: string) => {
	onNuxtReady(() => {
		if (eggName == 'einruckeln') {
			function wackeln() {
				let x = Math.floor(Math.random() * 10) - 5;
				let y = Math.floor(Math.random() * 10) - 5;
				document.body.style.transform = `translate(${x}px, ${y}px)`;
			}
			let wackelnInterval = setInterval(wackeln, 50);
			const { vibrate, stop, isSupported } = useVibrate({ pattern: [300, 100, 300, 500, 300, 100, 300, 500, 300, 100, 300] })
			// Start the vibration, it will automatically stop when the pattern is complete:
			if (isSupported) {
				vibrate()
			}
			setTimeout(() => {
				clearInterval(wackelnInterval);
				document.body.style.transform = `translate(0px, 0px)`;
			}, 15000);
		} else if (eggName == 'btdev') {
			useUserGroup().value = 'internal'
		} else if (eggName == 'pride') {
			const header = document.querySelector('.sticky-header');
			header.style.background = 'linear-gradient(rgb(228,3,3) 0%, rgb(228,3,3) 16%, rgb(255,140,0) 16%, rgb(255,140,0) 32%, rgb(255,237,0) 32%, rgb(255,237,0) 48%, rgb(0,128,38) 48%, rgb(0,128,38) 64%, rgb(0,77,255) 64%, rgb(0,77,255) 80%, rgb(117,7,135) 80%, rgb(117,7,135) 100%) 0px 0px / 100% 200%';
			header.style.animation = `eggAnimation 2s linear infinite reverse`;
		}
	})
}


export const switchToCentralStore = async (updateFunction: any) => {
	try {
		console.log("change store event");
		useStoreCookie().value = "e_2879130";

		const csrfToken = await $fetch("/api/neo/frontend/_api/user/getCsrfToken?campaignid=", {
			method: "GET",
		});
		await $fetch('/api/switchStore?branch_id=e_2879130', {
			headers: {
				'csrf-token': csrfToken?.csrfToken
			}
		});
		updateFunction("e_2879130");
		console.log("update function called");
		await navigateTo("/shop/unsere-produkte/tv-audio?campaignID", { external: true });
	} catch (error) {
		console.warn("could not change store");
	}
}

export function sendBeacon(ping) {
	try {
		if (ping && typeof ping == 'string' && !ping.includes('undefined'))
			new Image().src = ping;
	} catch (error) {
		console.warn('could not phone home', error);
	}
}

export const changeStoreById = async (newId: string) => {
	const {
		isSupported,
		data,
		post,
		error
	} = useBroadcastChannel({ name: 'fmarkt-channel' });

	console.debug("[bt-default] store changed event");
	console.debug("[bt-default] found " + useStoreCookie()?.value + " in cookie vs " + newId);
	// if (newId !== useStoreCookie().value) {
	// useStoreCookie().value = newId;
	useReservationCartItemsCount().value = 0;
	useShoppingCartItemsCount().value = 0;
	useNuxtApp().$flags.setTrait('storeId', newId);
	clearNuxtData(["currentUserProfile", "currentShoppingCart", "currentReservationCart", "shoppingCartItemsCount", "reservationCartItemsCount"]);
	clearNuxtState(['navigation_treeMAIN', 'navigation_treeSUPER', 'navigation_treeFOOTER'])	
	console.debug('updating bla', useCurrentUserState())
	const rout = useRoute()?.fullPath?.toLowerCase();
	if (rout.includes('campaignid') || rout.includes('branch_id')) {
		const cleanQuery = { ...useRoute().query }
		Object.keys(cleanQuery).forEach(param => {
			if (param?.toLowerCase() == 'campaignid' || param?.toLowerCase() == 'branch_id') {
				delete cleanQuery[param];
			}
		})
		console.debug('got cleaned up query', cleanQuery, { query: cleanQuery, path: useRoute().path })
		await navigateTo({ query: cleanQuery, path: useRoute().path, force: true });
	}
	await useFetch('/api/switchStore?branch_id=' + newId);
	const { data: newStore } = await useFetch('/api/store', { query: { storeId: newId } });
	if (useCurrentStore()?.value?.storeId !== newStore?.value?.storeId) {
		await nextTick(() => {
			useCurrentStore().value = newStore?.value;
			refreshCookie(ProvideNames.STORE_COOKIE_NAME);
		})
	} else {
		console.warn('store already changed or ', newStore?.value);
	}
	getExpNavigationTree('MAIN').data.value = await fetchNavigationTree('MAIN') ;
	getExpNavigationTree('SUPER').data.value = await fetchNavigationTree('SUPER') ;
	getExpNavigationTree('FOOTER').data.value = await fetchNavigationTree('FOOTER') ;
	getExpNavigationTree('FOOTER_HARDLINKS').data.value = await fetchNavigationTree('FOOTER_HARDLINKS') ;
	getExpNavigationTree('FOOTER_SOCIAL').data.value =await fetchNavigationTree('FOOTER_SOCIAL') ;

	if (isSupported?.value) {
		post(newId);
	}
}